import PropTypes from 'prop-types';
import React from 'react';
import {Button} from '@chakra-ui/react';
import {FaBullhorn} from '@react-icons/all-files/fa/FaBullhorn';

const speakNow = text => {
  // Check if Speech Synthesis supported
  if (typeof window !== 'undefined' && 'speechSynthesis' in window) {
    const voiceMsg = new SpeechSynthesisUtterance(text);
    voiceMsg.lang = 'en-US';
    window.speechSynthesis.speak(voiceMsg);
  } else {
    // Speech Synthesis Not Supported
    alert("Sorry, your browser doesn't support text to speech!");
  }
};

export default function Speech({text}) {
  return (
    <>
      {typeof window !== 'undefined' && 'speechSynthesis' in window && (
        <Button
          colorScheme="green"
          variant="outline"
          leftIcon={<FaBullhorn />}
          onClick={() => speakNow(text)}
        >
          {text}
        </Button>
      )}
    </>
  );
}

Speech.propTypes = {
  text: PropTypes.string.isRequired
};
