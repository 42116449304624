import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout.js';
import PropTypes from 'prop-types';
import React from 'react';
import Seo from '../components/Seo';
import Speech from '../components/Speech';
import Traduction from '../components/Traduction';
import {Box, Flex, Heading, Image, Link, Text} from '@chakra-ui/react';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {graphql} from 'gatsby';

const chakraUiComponents = {
  h1: props => (
    <Heading as="h1" paddingY="5" size="2xl" color="green.600" {...props} />
  ),
  h2: props => (
    <Heading as="h2" paddingY="4" size="xl" color="green.600" {...props} />
  ),
  h3: props => (
    <Heading as="h3" paddingY="2" size="lg" color="green.600" {...props} />
  ),
  h4: props => (
    <Heading as="h4" paddingY="3" size="md" color="green.600" {...props} />
  ),
  h5: props => (
    <Heading as="h5" paddingY="2" size="sm" color="green.600" {...props} />
  ),
  h6: props => (
    <Heading as="h6" paddingY="2" size="xs" color="green.600" {...props} />
  ),
  p: props => (
    <Text lineHeight={2} paddingBottom="4" fontSize="lg" {...props} />
  ),
  a: props => <Link as="a" color="green.600" {...props} />,
  img: props => <Image marginBottom="4" {...props} />,
  Speech,
  Traduction
};

function DefinitionEntry({body, cover}) {
  return (
    <Box paddingY="2">
      {cover && (
        <Box paddingY="2">
          <Image src={cover} />
        </Box>
      )}
      <Box paddingY="4">
        <MDXProvider components={chakraUiComponents}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Box>
    </Box>
  );
}

function ArticleEntry({body, cover, timeToRead, date}) {
  return (
    <Box paddingY="2">
      {date && (
        <Flex justifyContent={'space-between'}>
          <p>{timeToRead} min</p>
          <p>{date}</p>
        </Flex>
      )}
      {cover && (
        <Box paddingY="2">
          <Image src={cover} />
        </Box>
      )}
      <Box paddingY="4">
        <MDXProvider components={chakraUiComponents}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Box>
    </Box>
  );
}

export default function MarkdownPages({data}) {
  const {frontmatter, body, timeToRead, excerpt, slug} = data.mdx;

  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        description={excerpt}
        image={frontmatter.cover}
      />
      {slug && <Breadcrumbs slug={`/${slug}`} />}
      {frontmatter.categorie === 'definition' && (
        <DefinitionEntry
          link={`/${slug}`}
          title={frontmatter.title}
          body={body}
          cover={frontmatter.cover}
        />
      )}
      {frontmatter.categorie !== 'definition' && (
        <ArticleEntry
          link={`/${slug}`}
          {...frontmatter}
          body={body}
          timeToRead={timeToRead}
        />
      )}
    </Layout>
  );
}

MarkdownPages.propTypes = {
  data: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: {eq: $id}) {
      body
      slug
      timeToRead
      excerpt
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "fr-FR")
        title
        cover
        categorie
      }
    }
  }
`;
