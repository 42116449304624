import * as React from 'react';
import PropTypes from 'prop-types';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react';
import {FaChevronRight} from '@react-icons/all-files/fa/FaChevronRight';
import {Link} from 'gatsby';

function buildCrumb(crumb, isLast = false) {
  console.log(crumb);
  const crumbname = crumb.length === 0 ? 'home' : crumb;
  const path = crumb.length === 0 ? '/' : isLast ? '' : `/${crumb}/`;
  return (
    <BreadcrumbItem
      key={crumbname}
      isCurrentPage={isLast}
      color={isLast && 'green.700'}
    >
      <BreadcrumbLink as={Link} to={path} textTransform="capitalize">
        {crumbname}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
}

export default function Breadcrumbs({slug}) {
  const crumbs = slug.split('/');
  return (
    <Breadcrumb
      fontSize="xl"
      spacing="6px"
      fontWeight="700"
      color="green.500"
      separator={<FaChevronRight size="0.8em" color="green.700" />}
    >
      {crumbs.map((crumb, idx) => buildCrumb(crumb, idx === crumbs.length - 1))}
    </Breadcrumb>
  );
}
Breadcrumbs.propTypes = {
  slug: PropTypes.string.isRequired
};
