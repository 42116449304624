import PropTypes from 'prop-types';
import React from 'react';
import {Flex, Text} from '@chakra-ui/react';

export default function Traduction({text}) {
  return (
    <header>
      <Flex>
        <Text fontSize="lg" mr="2">
          <span aria-label="français">Traduction 🇫🇷 :</span>
        </Text>
        <Text fontStyle={'italic'} fontSize="lg" fontWeight="600">
          {text}
        </Text>
      </Flex>
    </header>
  );
}

Traduction.propTypes = {
  text: PropTypes.string.isRequired
};
